// import { Link } from "gatsby"
import React, { useRef } from "react"
// import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import { globalHistory } from "@reach/router"
import { Waypoint } from 'react-waypoint';

// s4n
import "./../../../assets/sass/components/service/scroll-top.scss"


const ScrollTop = (props) => {
    const { children, intl, serviceId } = props;

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    // useEffect(() => {
    //     loadScripts(props);
    // }, []);
    // const loadScripts = async (props) => {
    //     // for this feature we enable it in in layout.js loaded scripts
    // }

    const ref = useRef(null);

    const path = globalHistory.location.pathname;

    const handleEnter = () => {
// console.log(`onEnter`)
        // ref.current.className = "visible";
        ref.current.classList.add("visible");
    }

    const handleLeave = () => {
// console.log(`onLeave`)
        // ref.current.className = "visible";
        ref.current.classList.remove("visible");
    }

    return (
        <React.Fragment>

            {/* @see: If you don't pass a child into your Waypoint, then you can think of the waypoint as a line across the page. Whenever that line crosses a boundary, then the onEnter or onLeave callbacks will be called. */}
            <Waypoint 
                // scrollableAncestor={window}
                bottomOffset="-100%" 
                onEnter={handleEnter}
                onLeave={handleLeave}
            />

            <div ref={ref} id="scrollTop" className="scrollTop">
                <a href={`${path}#top`} title="Go to top">
                    <i className="fas fa-angle-up"></i>
                </a>
            </div>
        </React.Fragment>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default injectIntl(ScrollTop)
